import React from "react";
import * as classes from "./index.module.less";

export default () => (
  <div className={classes.container}>
    <div className={classes.story}>
      <h2>Our Story</h2>
      <p>
        In 2013, co-founders Shlomo and Rich were expats living in China,
        witnessing the rapid widespread adoption of mobile technology. They saw
        an opportunity for global brands to dramatically expand their market to
        this side of the world, but were shocked at the roadblocks even the
        largest and most established of companies faced trying to do business
        here.
      </p>
      <p>
        <b>
          We have since grown to offer a robust suite of services a company
          needs to succeed in China, such as localization, monetization, user
          acquisition, hosting, legal compliance, game licensing, e-commerce,
          customer service, social media marketing, and more.
        </b>
      </p>
      <br />
      <h2 className={classes.center}>Our Team</h2>
      <p>
        Our Beijing-based, international team of English speakers create a
        diverse and fun working atmosphere for our clients. Easy to reach by
        messaging, email and phone call, each of our clients has a dedicated
        account manager who takes time to understand their business to provide
        expert guidance.
      </p>
    </div>
  </div>
);
