import React from "react";
import * as classes from "./index.module.less";
import { StaticImage } from "gatsby-plugin-image";

export default () => (
  <div className={classes.container}>
    <div className={classes.text}>
      <h1>
        About <b>AppInChina</b>
      </h1>
      <h2>Our Mission</h2>
      <p>
        AppInChina was born to streamline access to the world's biggest market
        of 1.4+ billion Chinese mobile users for app and game publishers, big
        and small
      </p>
    </div>
    <div className={classes.image}>
      <StaticImage
        src="../../../images/photos/building.jpg"
        alt="China CCTV Tower"
        placeholder="blurred"
      />
    </div>
    <div className={classes.box}></div>
  </div>
);
